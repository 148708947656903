import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout/layout';
import { graphql } from 'gatsby';
import Section from '../components/Section/section'
import { ArticleSection } from '../components/Section/section.css'
import ArticleInfo from '../components/article/articleInfo/articleInfo'
import ArticleStatement from '../components/Statement/articleStatement'
import ArticleBody from '../components/article/articleBody/articleBody'
import { useEffect } from 'react'
import Seo from '../components/seo'
import ShareTab from '../components/shareTab/shareTab'
import Typeform from '../components/typeform/typeform'
import TypeformCTA from '../components/typeform/typeformCTA/typeformCTA'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SEO from '../components/seo';
// import { siteUrl } from "../../site-config"
import Icon from '../images/icon.png'
const Article = ({ data }) => {

  const siteUrl = `https://mag.ntheos.com`
  console.log("data", data, siteUrl)
  console.log(`${siteUrl}${data.allFile.edges[0].node.publicURL}`)
  const post = data.markdownRemark
  const d = new Date(post.frontmatter.date).toLocaleString()
  const date = d.slice(0, d.indexOf(","))
  console.log(data)
  return (
    <Layout>
      <SEO
        description={post.frontmatter.description}
        image={
          post.frontmatter.cover !== null &&
            post.frontmatter.cover !== undefined ?
            post.frontmatter.cover.childImageSharp.fluid.src :
            `https://mag.ntheos.com/img/icon.jpeg`
        }
        title={post.frontmatter.title}
      />

      <ArticleStatement >
        {post.frontmatter.title}
      </ArticleStatement>
      <ArticleInfo cover={post.frontmatter.cover !== null ?
        post.frontmatter.cover.childImageSharp.fluid : null}
        author={post.frontmatter.author || "The Ntheos Team"} date={date}
        subtitle={post.frontmatter.description} />
      <Section type="article" tags={post.frontmatter.tags} size=''>
        <ArticleSection>
          <ShareTab shareInfo={post.frontmatter} />
          <ArticleBody opener={post.frontmatter.opener} html={{ __html: post.html }} />
        </ArticleSection>

      </Section>
      {/* <Section type="cta" bg="white" section='Join the alpha' size='medium'>
        <TypeformCTA>
          <Typeform />
        </TypeformCTA>
      </Section> */}
    </Layout>
  )
};

Article.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Article;

export const query = graphql`
  query($slug: String!) {
    allFile(
      filter: { sourceInstanceName: { eq: "images" }, relativePath: { in: "icon.png" } }
    ) {
      edges {
        node {
          publicURL
          absolutePath
          sourceInstanceName
          relativePath
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        date
        tags
        opener
        author
        cover {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
  }
          }
        }
      }
    }
  }
`
  ;
